import Component from "../component";
import AsyncListManager, { AsyncListManagerEvent } from "../../helpers/async-list-manager.js";
import Dropdown from "../../helpers/dropdown";

export default class Listing extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();

    this.type = this.dom.component.dataset.type;
    this.filtersId = {};
  }

  _retrieveDOM() {
    this.dom.listCards = this.dom.component.querySelector(".js-container-cards");
    this.dom.loadMore = this.dom.component.querySelector(".js-load-more");
    this.dom.filtersItems = this.dom.component.querySelectorAll(".js-filter-item");
    this.dom.btnFilters = this.dom.component.querySelector(".js-btn-filters");
    this.dom.closeFilters = this.dom.component.querySelectorAll(".js-close-filters");
    this.dom.popinFilters = this.dom.component.querySelector(".js-popin-filters");
    this.dom.resetBtn = this.dom.component.querySelector(".js-btn-reset");
    this.dom.searchBtn = this.dom.component.querySelector(".js-btn-search");
    this.dom.inputSearch = this.dom.component.querySelector(".js-input-search");
    this.dom.filterButton = this.dom.component.querySelector(".filter-button");
    this.dom.filterButtonDesktop = this.dom.component.querySelector(".filter-button-desktop");
    this.dom.validateButton = this.dom.component.querySelector(".js-validate-filters");

    this.dom.promoButton = this.dom.component.querySelector(".js-promo-button");
    this.dom.promoValue = this.dom.component.querySelector(".js-promo-value");
  }

  init() {
    //init load more
    this._ALM = new AsyncListManager("sp_theme_posts_load_ajax", this.dom.loadMore);

    //Event click filters items
    for (let i = 0; i < this.dom.filtersItems.length; i++) {
      this.dom.filtersItems[i].addEventListener("click", this.manageFilters.bind(this));
    }
    //Options for request load more
    this.opts = {
      isSearch: true,
      type: this.type,
      filters: this.filtersId
    };

    this._ALM.setOpts(this.opts);

    //Set default filter without banner filter
    if (this.dom.component.dataset.defaultFilterId) {
      this.filtersId["type"] = {
        value: this.dom.component.dataset.defaultFilterId,
        name: this.dom.component.dataset.defaultFilterSlug,
        type: "taxo"
      };
    }

    if (this.dom.promoButton) {
      this.dom.promoButton.addEventListener("click", this._clickPromo.bind(this));
    }

    //Event click load more
    if (this.dom.loadMore) {
      this.dom.loadMore.addEventListener("click", this._clickLoadMore.bind(this));
    }

    //Event click btn filters (only mobile)
    if (this.dom.btnFilters) {
      this.dom.btnFilters.addEventListener("click", this._managePopin.bind(this));
    }

    //Event click close filters (only mobile)
    if (this.dom.closeFilters) {
      for (let index = 0; index < this.dom.closeFilters.length; index++) {
        this.dom.closeFilters[index].addEventListener("click", this._closePopin.bind(this));
      }
    }

    //Event click btn search
    if (this.dom.searchBtn && this.dom.inputSearch) {
      this.dom.searchBtn.addEventListener("click", this._searchProcess.bind(this));

      if (this.dom.validateButton) {
        this.dom.validateButton.addEventListener("click", this._searchProcess.bind(this));
      }

      this.dom.inputSearch.addEventListener("keypress", event => {
        if (event.keyCode == 13) {
          this._searchProcess();
          this.dom.inputSearch.blur();
        }
      });
    }

    //Event click btn reset search
    if (this.dom.resetBtn && this.dom.inputSearch) {
      this.dom.resetBtn.addEventListener("click", this._resetSearch.bind(this));
    }

    if (this.dom.filterButton) {
      this.dom.filterButton.addEventListener("click", this._toggleFilters.bind(this));
    }
    if (this.dom.filterButtonDesktop) {
      this.dom.filterButtonDesktop.addEventListener("click", this._toggleFilters.bind(this));
    }

    if (this.dom.validateButton) {
      this.dom.validateButton.addEventListener("click", this._toggleFilters.bind(this));
    }

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    for (const key of searchParams.keys()) {
      const value = searchParams.get(key);
      const realKey = key.replace("f_", "");
      const input = this.dom.component.querySelector(`input[name=${realKey}][data-id="${value}"]`);
      if (input) {
        input.checked = true;
        this.filtersId[realKey] = {
          value: value,
          name: input.getAttribute("name"),
          type: input.getAttribute("type")
        };

        this._ALM.load(this.opts);
      }
    }

    this._ALM.on(AsyncListManagerEvent.UPDATED, xhr => {
      console.log("UPDATED", xhr);
      this._injectData(xhr);

      // adds dropdown effect to faq cards
      if (this.type === "faq") {
        const cards = document.querySelectorAll(".ct--CardExpand");
        cards.forEach(card => {
          const dropdown = new Dropdown({ domComponent: card });
        });
      }
    });
  }

  _clickPromo() {
    this.dom.promoButton.classList.toggle("active");
  }

  _toggleFilters() {
    this.dom.popinFilters.classList.toggle("show");
  }

  _searchProcess() {
    this.opts.searchValue = this.dom.inputSearch.value;

    this._ALM.load(this.opts);
  }

  _resetSearch() {
    this.dom.inputSearch.value = "";
    this.opts.searchValue = "";

    this._ALM.load(this.opts);
  }

  manageFilters() {
    setTimeout(() => {
      const themeDom = this.dom.component.querySelector("input[name=category]:checked");
      const campusDom = this.dom.component.querySelector("input[name=campus]:checked");
      const typeDom = this.dom.component.querySelector("input[name=type]:checked");
      const trainingDom = this.dom.component.querySelector("input[name=trainings]:checked");
      const wordsDom = this.dom.component.querySelector("input[name=alphabet]:checked");
      const promoDom = this.dom.component.querySelector("input[name=promo]:checked");

      if (themeDom) {
        this.filtersId[themeDom.getAttribute("name")] = {
          value: themeDom.dataset.id,
          name: themeDom.dataset.catSlug,
          type: "taxo"
        };
      }

      if (typeDom) {
        this.filtersId[typeDom.getAttribute("name")] = {
          value: typeDom.dataset.id,
          name: typeDom.dataset.catSlug,
          type: "taxo"
        };
      }

      if (campusDom) {
        this.filtersId[campusDom.getAttribute("name")] = {
          value: campusDom.dataset.id,
          name: campusDom.getAttribute("name"),
          type: "meta"
        };
      }

      if (trainingDom) {
        this.filtersId[trainingDom.getAttribute("name")] = {
          value: trainingDom.dataset.id,
          name: trainingDom.getAttribute("name"),
          type: "meta"
        };
      }

      if (wordsDom) {
        this.filtersId[wordsDom.getAttribute("name")] = {
          value: wordsDom.dataset.id,
          name: wordsDom.dataset.catSlug,
          type: "taxo"
        };
      }

      if (promoDom) {
        this.filtersId[promoDom.getAttribute("name")] = {
          value: promoDom.dataset.id,
          name: promoDom.dataset.catSlug,
          type: "taxo"
        };

        this.dom.promoButton.classList.remove("active");

        if (parseInt(promoDom.value)) {
          this.dom.promoValue.querySelector(".text").innerHTML = promoDom.value;
          this.dom.promoValue.classList.add("show");
        } else {
          this.dom.promoValue.classList.remove("show");
        }
      }

      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      for (const key in this.filtersId) {
        searchParams.set("f_" + key, this.filtersId[key].value);
      }
      url.search = searchParams.toString();
      window.history.pushState({}, "", url);

      this._ALM.load(this.opts);
    }, 400);
  }

  _clickLoadMore(event) {
    this._ALM.loadMore(event);
  }

  _injectData(xhr) {
    if (xhr.data.has_results == false) {
      this._addNoResult();
      if (this.dom.loadMore) {
        this.dom.loadMore.classList.remove("show");
      }
    } else {
      //rm no results
      this._removeNoResult();

      //manage load more
      if (xhr.data.load_more == true) this.dom.loadMore.classList.add("show");
      else if (this.dom.loadMore) {
        this.dom.loadMore.classList.remove("show");
      }

      // Insert dom
      if (xhr.data.page == 1) {
        this.dom.listCards.innerHTML = xhr.data.posts_html;
      } else {
        this.dom.listCards.insertAdjacentHTML("beforeend", xhr.data.posts_html);
      }

      //Animation at insert
      this.dom.cards = this.dom.listCards.querySelectorAll(".to-animate");
      for (let i = 0; i < this.dom.cards.length; i++) {
        let delay = (i + 1) * 200;
        this.animateCard(i, delay);
      }
    }
  }

  _closePopin() {
    this.dom.popinFilters.classList.remove("show");
  }

  _managePopin() {
    this.dom.popinFilters.classList.add("show");
  }

  _addNoResult() {
    this.dom.component.classList.add("no-results");
  }

  _removeNoResult() {
    this.dom.component.classList.remove("no-results");
  }

  animateCard(i, delay) {
    setTimeout(() => {
      this.dom.cards[i].classList.remove("to-animate");
    }, delay);
  }

  unbind() {
    this.dom.filterButton.removeEventListener("click", this._toggleFilters.bind(this));
    this.dom.validateButton.removeEventListener("click", this._toggleFilters.bind(this));
    this.dom.promoButton.removeEventListener("click", this._clickPromo.bind(this));
  }

  /*
	init() {
	}
	bind() {
	}
	unbind() {
	}
	resize(width, height) {
	}
	*/
}
